import styled from 'styled-components';

export const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  @media (max-width: 500px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`;

export const Embed = styled.div``;
