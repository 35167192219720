import React from 'react';
import * as S from './FBCommentPlugin.styles';

const FBCommentPlugin = props => {
  const { url, width } = props;

  return (
    <S.Container style={{ maxWidth: width ?? 750 }}>
      <div
        className="fb-comment-embed"
        data-href={url}
        data-width={width ?? 750}
        data-include-parent="false"
      ></div>
    </S.Container>
  );
};

export default FBCommentPlugin;
